/*!
 *
 * Helerok - Template
 *
 * Version: 0.0.1
 * Author: LEC
 *
 */
.aside-collapsed .sidebar > .sidebar-nav > li, .aside-collapsed-text .sidebar > .sidebar-nav > li {
  width: 39px; }

.aside-collapsed .wrapper .aside-container, .aside-collapsed .wrapper .aside-container .aside-inner {
  width: 40px; }

.aside-collapsed .wrapper .section-container, .aside-collapsed .wrapper .footer-container {
  margin-left: 40px; }

.aside-collapsed .wrapper .aside-container .nav-floating {
  margin-left: 40px; }

.aside-collapsed-text .user-block > .user-block-picture, .aside-collapsed .user-block > .user-block-picture {
  float: none;
  margin: 0 auto;
  width: 36px; }

/* ------------------------------------------------- */
.wrapper .aside-container {
  width: 185px; }

.wrapper .aside-container .aside-inner {
  width: 185px; }

/*
nav.sidebar {
    width: 185px;
}
*/
.sidebar-nav {
  font-size: 12px; }

.sidebar .nav-heading {
  padding: 6px 11px; }

.sidebar-nav > li > a, .sidebar-nav > li > .nav-item {
  padding: 6px 12px; }

.sidebar-subnav > li > a, .sidebar-subnav > li > .nav-item {
  padding: 5px 34px; }

.user-block {
  padding: 13px 0 5px; }

.sidebar-nav > li > a > em, .sidebar-nav > li > .nav-item:hover > em.go-to-submenu {
  display: inline-block !important; }

.sidebar-subnav > li > a, .sidebar-subnav > li > .nav-item {
  padding: 5px 12px 5px 34px; }

@media (min-width: 768px) {
  .wrapper .section-container, .wrapper .footer-container {
    margin-left: 185px; }
  .topnavbar .navbar-header {
    width: 185px;
    text-align: center; } }

/* ------------------------------------------------- */
.user-block-status .img-thumbnail {
  height: 60px !important; }

.brand-logo {
  text-align: left; }

.brand-logo img {
  /*width: 85px;*/
  width: 36px; }

.brand-logo-collapsed {
  text-align: left;
  padding: 6px; }

.brand-logo-collapsed img {
  width: 31px; }

.card {
  font-size: 0.775rem; }

.card-header {
  padding: 0.325rem 0.9375rem !important; }

.card-body.app-no-padding {
  padding: 0 0.9375rem !important; }

.card-body.app-padding {
  padding: 0.4375rem 0.9375rem !important; }

.card .table th {
  border-bottom: 2px solid #ffd59f !important; }

/*
.card .list-view .list-view-header {
    padding: 5px 15px;
}

.card .list-view .list-view-header .list-view-header-col > .row > div {
    border-bottom: 2px solid #ffd59f !important;
}
*/
.card .list-view .list-view-header {
  padding: 5px 15px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ffd59f !important; }

.table {
  font-size: 0.775rem; }

.table th, .table td {
  padding: 0.35rem 0.75rem; }

.active-form .col-form-label {
  /*padding-bottom: calc(0.075rem + 1px);*/
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.175rem;
  font-weight: bold; }

.active-form .form-group {
  font-size: 0.775rem;
  margin-bottom: 8px; }

.active-form.form-horizontal .form-group {
  margin-bottom: 0; }

.form-control {
  padding: 0.175rem 0.775rem;
  font-size: 0.775rem;
  height: 1.7875rem; }

.form-label {
  /*padding: 0.175rem 0.775rem;*/
  padding: 0 0.375rem;
  font-size: 0.775rem;
  height: 1.5rem; }

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }

.invalid-text-area {
  border: 1px solid #f05050; }

.list-view-item.row [class*=col] {
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*width: 100%;*/
  /*white-space: nowrap;*/ }

.label-text {
  padding: 4px 5px;
  font-weight: bolder;
  font-size: 10px;
  text-transform: uppercase;
  color: #455A64; }

.label-value {
  min-height: 20px;
  padding: 2px 5px;
  /*
    font-size: 10px;
    font-weight: 600;
    */
  background-color: #ECEFF1;
  border-radius: 4px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.text-break {
  white-space: break-spaces; }

.fixed {
  position: fixed !important; }

.btn-hr:hover {
  border-radius: 90px;
  /*padding: 5px 10px;*/
  /*background-color: #eee;*/
  color: #777;
  width: 48px;
  height: 48px; }

.modal-header {
  padding: 0.5rem 1rem; }

/*
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
*/
.active-form > .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .active-form > .row > .col,
  .active-form > .row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.active-form .table .form-check-input {
  position: relative;
  margin-top: 0;
  margin-left: -1.25rem; }

.hide {
  display: none; }

.fa-md {
  font-size: 1.1666em; }

.table tr.collapse > td {
  border-top: none; }

.table tr.selected-row {
  background-color: #f0f1f2; }

.inline-block {
  display: inline-block; }

.form-control-table-cell {
  display: inline;
  width: auto;
  height: auto;
  padding: 1px 2px;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.52857;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dde6e9;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.tab-label-badge {
  position: relative;
  top: -7px;
  right: 1px;
  padding: 2px 5px; }

.toast-dark {
  background: #333 !important;
  color: white !important;
  border-radius: 10px !important;
  text-align: center !important; }

.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }

.countdown-item {
  color: #111;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px; }

.countdown-item span {
  color: #333;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase; }

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px; }

.react-bootstrap-table table > tbody > tr > td:nth-child(1),
.react-bootstrap-table table > tfoot > tr > td:nth-child(1),
.react-bootstrap-table table > thead > tr > th:nth-child(1) {
  background-color: #fafbfc;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1; }

.Toastify__progress-bar--default {
  background: #f05050 !important; }

.mood-btn {
  background: none;
  border: none;
  width: 28px;
  height: 28px;
  background-image: url("/img/mood-unhappy.png");
  background-size: 28px 28px;
  padding: 4px;
  background-position: center center;
  box-sizing: content-box;
  background-repeat: no-repeat;
  margin: 0 7px;
  position: relative;
  overflow: hidden;
  border-radius: 3px; }

.mood-btn:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.03); }

.mood-btn[data-rating="2"] {
  background-image: url("/img/mood-sad.png"); }

.mood-btn[data-rating="3"] {
  background-image: url("/img/mood-neutral.png"); }

.mood-btn[data-rating="4"] {
  background-image: url("/img/mood-satisfied.png"); }

.mood-btn[data-rating="5"] {
  background-image: url("/img/mood-happy.png"); }

@font-face {
  font-family: 'JuliusSansOne';
  src: url("/fonts/JuliusSansOne-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'GloriaHallelujah';
  src: url("/fonts/GloriaHallelujah-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Righteous';
  src: url("/fonts/Righteous-Regular.ttf") format("truetype"); }

/*** Ordenamiento listview **/
table.lv-table thead > tr > td.sorting, table.lv-table thead > tr > td.sorting_asc, table.lv-table thead > tr > td.sorting_desc, table.lv-table thead > tr > th.sorting, table.lv-table thead > tr > th.sorting_asc, table.lv-table thead > tr > th.sorting_desc {
  padding-right: 30px; }

table.lv-table thead .sorting, table.lv-table thead .sorting_asc, table.lv-table thead .sorting_asc_disabled, table.lv-table thead .sorting_desc, table.lv-table thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative; }

table.lv-table td, table.lv-table th {
  box-sizing: initial; }

table.lv-table thead .sorting:after, table.lv-table thead .sorting:before, table.lv-table thead .sorting_asc:after, table.lv-table thead .sorting_asc:before, table.lv-table thead .sorting_asc_disabled:after, table.lv-table thead .sorting_asc_disabled:before, table.lv-table thead .sorting_desc:after, table.lv-table thead .sorting_desc:before, table.lv-table thead .sorting_desc_disabled:after, table.lv-table thead .sorting_desc_disabled:before {
  position: absolute;
  bottom: .9em;
  display: block;
  opacity: .3; }

table.lv-table thead .sorting:before, table.lv-table thead .sorting_asc:before, table.lv-table thead .sorting_asc_disabled:before, table.lv-table thead .sorting_desc:before, table.lv-table thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191"; }

/*after*/
table.lv-table thead .sorting:after, table.lv-table thead .sorting_asc:after, table.lv-table thead .sorting_asc_disabled:after, table.lv-table thead .sorting_desc:after, table.lv-table thead .sorting_desc_disabled:after {
  right: .5em;
  content: "\2193"; }

table.lv-table thead .sorting:after, table.dalv-tabletaTable thead .sorting:before, table.lv-table thead .sorting_asc:after, table.lv-table thead .sorting_asc:before, table.lv-table thead .sorting_asc_disabled:after, table.lv-table thead .sorting_asc_disabled:before, table.lv-table thead .sorting_desc:after, table.lv-table thead .sorting_desc:before, table.lv-table thead .sorting_desc_disabled:after, table.lv-table thead .sorting_desc_disabled:before {
  position: absolute;
  bottom: .9em;
  display: block;
  opacity: .3; }

table.lv-table thead .sorting_asc:before, table.lv-table thead .sorting_desc:after {
  opacity: 1; }

/*** Ordenamiento listview **/
